<template>
  <div>
    <div
      v-for="(item, i) in data.objects"
      :key="i"
      class="q-mb-sm"
    >
      <component
        :is="types[item.type]"
        v-bind="{ data: item.data, rawData: item }"
        @click="handleClick"
      />
    </div>

    <div
      class="fixed-bottom-right text-right"
      style="bottom: 10px; right: 20px;"
    >
      <div class="q-mb-sm block q-ml-auto">
        <portal-target name="assembling-fab" />
      </div>

      <q-btn
        v-if="dataObjects[$entities.Orderadmin_Storage_Entity_Assemblage_Task]"
        class="q-mb-sm block q-ml-auto fab"
        fab
        size="lg"
        icon-right="print"
        color="light-blue-9"
        :label="$t('Print')"
        label-position="left"
        dark
        @click="handleClick({ type: 'storage.queue.task.print' })"
      />

      <q-btn
        v-if="queue"
        class="q-mb-sm block q-ml-auto fab"
        fab
        size="lg"
        icon-right="assignment_late"
        color="negative"
        :label="$t('Rejected tasks')"
        label-position="left"
        dark
        @click="handleOpenTasks"
      />

      <q-btn
        v-if="dataObjects['request-data']"
        fab
        class="q-mb-sm block q-ml-auto fab"
        size="lg"
        icon-right="archive"
        color="primary"
        :label="$t((dataObjects['request-data'] && dataObjects['request-data'].options && dataObjects['request-data'].options.label) || 'Box')"
        label-position="left"
        dark
        @click="handleOpenRequestData"
      />

      <q-btn
        v-if="dataObjects[$entities.Orderadmin_Storage_Entity_Assemblage_Task]"
        fab
        class="q-mb-sm block q-ml-auto fab"
        size="lg"
        icon-right="skip_next"
        color="dark"
        :label="$t('Skip task')"
        label-position="left"
        dark
        @click="handleClick({ type: 'storage.queue.task.reject' })"
      />
    </div>

    <q-dialog v-model="amountModal">
      <q-card style="min-width: 50vw;">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6 q-my-none">
            {{ $t('Amount') }}
          </div>

          <q-space />

          <q-btn
            icon="close"
            flat
            round
            dense
            @click="handleCloseAmount"
          />
        </q-card-section>

        <q-card-section class="row justify-center items-center">
          <q-btn
            color="light-blue-9"
            text-color="white"
            size="sm"
            :label="$t('Max') + ': ' + maxAmount"
            @click="handleAddAll"
          />

          <div
            class="col row items-center q-px-sm justify-center"
            style="max-width: 350px;"
          >
            <q-btn
              color="negative"
              text-color="white"
              size="sm"
              icon="remove"
              :disable="count <= 1 || isLoading"
              @click="handleDecrease"
            />

            <div
              class="col q-px-sm"
              style="min-width: 80px; max-width: 250px;"
            >
              <q-input
                standout="bg-teal text-white"
                type="number"
                :model-value="count"
                :label="$t('Amount')"
                :disable="isLoading"
                dense
                @update:model-value="handleAmount"
                @focus="onFocus"
                @blur="onFocusOut"
              />
            </div>

            <q-btn
              color="positive"
              text-color="white"
              size="sm"
              icon="add"
              :disable="isLoading || count >= maxAmount"
              @click="handleIncrease"
            />
          </div>

          <q-btn
            color="light-blue-9"
            text-color="white"
            size="sm"
            :label="$t('Submit')"
            @click="handleItemClick({ data: product })"
          />
        </q-card-section>

        <q-card-section class="q-my-sm">
          <dynamic-products-collection :data="[product]" />
        </q-card-section>
      </q-card>
    </q-dialog>

    <barcode-input-modal ref="barcodeInputModal" />

    <delivery-request-places-modal ref="drPlacesModal" />

    <confirm-modal ref="confirmModal" />

    <entity-update-modal ref="entityUpdateModal" :manual="fastMode" />

    <request-data-modal
      ref="requestDataModal"
      @submit="handleRequestData"
      @close="handleRequestDataClose"
    />

    <assembling-tasks-modal ref="assemblingTasksModal" />

    <portal v-if="!!queue" to="assembling-queues">
      <q-btn
        color="dark"
        text-color="white"
        size="sm"
        class="q-mr-sm"
        :label="$t('All queues')"
        no-caps
        @click="handleClick({ type: 'storage.queue.choose' })"
      />
    </portal>

    <portal v-if="!!queue" to="assembling-buttons">
      <q-btn
        color="dark"
        text-color="white"
        size="sm"
        :icon-right="isOpenQueue ? 'expand_less' : 'expand_more'"
        :label="$t('Returned')"
        no-caps
        @click="handleQueueOpenClose"
      />
    </portal>

    <portal v-if="!!queue" to="assembling-body">
      <q-slide-transition>
        <dynamic-product-tasks-table
          v-if="isOpenQueue"
          :queue="queue"
        />
      </q-slide-transition>
    </portal>
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Services
import { InstructionsService } from './../../../services/instructions.service'

// Components
import DesktopMessage from './../../../components/dynamic-components/desktop-message'
import DynamicCollection from './../../../components/dynamic-components/dynamic-collection'
import DynamicOrder from './../../../components/dynamic-components/dynamic-order'
import PickingItem from './../../../components/picking/picking-item'
import NewPickingPlace from './../../../components/picking/new-picking-place'
import PickingQueue from './../../../components/picking/picking-queue'
import BarcodeInputModal from '../../../components/barcode-input-modal/BarcodeInputModal'
import DynamicTask from '../../../components/dynamic-components/dynamic-task'
import DeliveryRequestPlacesModal from '../../../components/modals/DeliveryRequestPlacesModal.vue'
import DynamicProductsCollection from '../../../components/dynamic-components/dynamic-products-collection.vue'
import ConfirmModal from '../../../components/confirm-modal/ConfirmModal.vue'
import RequestDataModal from '../../../components/modals/RequestDataModal.vue'
import DynamicDeliveryRequest from './../../../components/dynamic-components/dynamic-delivery-request.vue'
import AssemblingTasksModal from '../../../components/modals/AssemblingTasksModal.vue'
import DynamicProductTasksTable from '../../../components/dynamic-components/dynamic-product-tasks-table.vue'
import OptionObject from '../../../components/dynamic-components/option-object.vue'
import EntityUpdateModal from '../../../components/modals/EntityUpdateModal.vue'

export default {
  name: 'ReturnContent',
  emits: ['stop-loading', 'start-loading', 'fast-mode', 'show-barcode-field', 'block', 'unblock'],
  components: {
    PickingQueue,
    PickingItem,
    DesktopMessage,
    NewPickingPlace,
    DynamicCollection,
    BarcodeInputModal,
    DynamicOrder,
    DynamicTask,
    DeliveryRequestPlacesModal,
    DynamicProductsCollection,
    ConfirmModal,
    RequestDataModal,
    DynamicDeliveryRequest,
    AssemblingTasksModal,
    DynamicProductTasksTable,
    EntityUpdateModal,
    OptionObject
  },
  props: {
    fastMode: {
      type: Boolean,
      default () {
        return false
      }
    },
    barcode: {
      type: Object,
      default () {
        return {}
      }
    },
    queues: {
      type: Array,
      default () {
        return []
      }
    },
    place: {
      type: [String, Number],
      default () {
        return null
      }
    }
  },
  data () {
    return {
      isOpenQueue: false,
      isLoading: false,
      types: {
        message: 'desktop-message',
        'Orderadmin\\DeliveryServices\\Entity\\DeliveryRequest': 'dynamic-delivery-request',
        // 'Orderadmin\\Storage\\Entity\\Assemblage\\Task': 'dynamic-task',
        'Orderadmin\\Storage\\Entity\\Place': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\Row': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\Section': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\StaticLocation': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\Dynamic': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\Employee': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\Sorting': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\Distribution': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\DistributionRejected': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\Assembly': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\Universal': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\Pallet': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\Defected': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Place\\Room': 'new-picking-place',
        'Orderadmin\\Storage\\Entity\\Item': 'picking-item',
        'Orderadmin\\Storage\\Entity\\Assemblage\\Queue': 'picking-queue',
        collection: 'dynamic-collection',
        'Orderadmin\\Products\\Entity\\Order': 'dynamic-order',
        'option': 'option-object',
        'Orderadmin\\Products\\Entity\\Order\\ReturnOrder': 'dynamic-order',
        'Orderadmin\\Products\\Entity\\Order\\RetailOrder': 'dynamic-order',
        'Orderadmin\\Products\\Entity\\Order\\WholesaleOrder': 'dynamic-order',
      },
      amountModal: false,
      maxAmount: 1,
      count: 1,
      queue: null,
      adapter: null,
      product: null,
      dataObjects: {},
      data: {
        place: this.place,
        objects: [
          {
            type: 'message',
            data: {
              position: 'bottom',
              text: 'Choose queue'
            }
          },
          ...this.queues
        ]
      },
      instructionsService: null,
      scannedBarcodes: []
    }
  },
  computed: {
    ...mapGetters([
      'printer',
      'settings'
    ])
  },
  watch: {
    barcode (newVal) {
      const collectionTaskName = this.getCollectionName(this.$entities.Orderadmin_Storage_Entity_Assemblage_OrderProductTask)

      if (this.data.event === 'storage.assembling.scan.item') {
        if (this.dataObjects[collectionTaskName]) {
          return this.handleProductScan(newVal)
        }

        const options = {
          description: this.$t('Product is not found. Do you want to search and add it as new product?')
        }

        return this.$refs.confirmModal.show(options)
          .then(isOk => {
            if (!isOk) {
              return
            }

            this.scannedBarcodes = [
              ...this.scannedBarcodes,
              { barcode: newVal.raw, quantity: this.count, timestamp: new Date().toISOString() }
            ]

            return this.handleSubmit(this.scannedBarcodes)
          })
      }

      this.handleBarcode(newVal)
    }
  },
  mounted () {
    this.instructionsService = new InstructionsService(this.$refs, this.$service.printer, (...params) => fetch(...params))
  },
  methods: {
    ...mapMutations([
      'addErrorNotification'
    ]),
    handleQueueOpenClose () {
      this.isOpenQueue = !this.isOpenQueue
    },
    handleCloseAmount () {
      this.count = 1
      this.amountModal = false
    },
    handleProductScan (barcode) {
      return this.updateCollection(barcode)
        .then(({ product }) => {
          if (!product) {
            const options = {
              description: this.$t('Product is not found. Do you want to search and add it as new product?')
            }

            return this.$refs.confirmModal.show(options)
              .then(isOk => {
                if (!isOk) {
                  return
                }

                this.scannedBarcodes = [
                  ...this.scannedBarcodes,
                  { barcode: barcode.raw, quantity: this.count, timestamp: new Date().toISOString() }
                ]

                return this.handleSubmit(this.scannedBarcodes)
              })
          }

          this.scannedBarcodes = [
            ...this.scannedBarcodes,
            { id: product.id, quantity: this.count, timestamp: new Date().toISOString() }
          ]

          this.product = product

          return this.executeInstructions(product.instructions)
            .then(() => {
              if (this.fastMode) {
                return false
              }

              return true
            })
            .then(isOk => {
              if (isOk) {
                return this.handleSubmit(this.scannedBarcodes)
              }

              return isOk
            })
            .then(isOk => {
              if (this.product.scannedCount < this.product._embedded.orderProduct.count) {
                this.maxAmount = this.product._embedded.orderProduct.count - this.product.scannedCount
                this.amountModal = true
              }

              return isOk
            })
            .finally(() => {
              this.count = 1
            })
        })
    },
    revertChanges (product) {
      const lastScan = this.scannedBarcodes[this.scannedBarcodes.length - 1]
      this.scannedBarcodes = this.scannedBarcodes.slice(0, this.scannedBarcodes.length - 1)

      this.product = {
        ...product,
        scannedCount: product.scannedCount - lastScan.quantity
      }

      const collectionTaskName = this.getCollectionName(this.$entities.Orderadmin_Storage_Entity_Assemblage_OrderProductTask)

      this.dataObjects = {
        ...this.dataObjects,
        [collectionTaskName]: {
          ...this.dataObjects[collectionTaskName],
          data: this.dataObjects[collectionTaskName].data.map(p => {
            return p.id === product.id
              ? { ...this.product }
              : p
          })
        }
      }

      this.data = {
        ...this.data,
        objects: this.data.objects.map(x => {
          return x.type === 'collection' && x.entityClass === this.$entities.Orderadmin_Storage_Entity_Assemblage_OrderProductTask
            ? this.dataObjects[collectionTaskName]
            : x
        })
      }

      return product
    },
    updateCollection (barcode) {
      const collectionTaskName = this.getCollectionName(this.$entities.Orderadmin_Storage_Entity_Assemblage_OrderProductTask)
      let isIncreased = false
      let updatedProduct = null

      let productWithMaxCount = null

      const collection =  {
        type: 'collection',
        entityClass: this.$entities.Orderadmin_Storage_Entity_Assemblage_OrderProductTask,
        ...this.dataObjects[collectionTaskName] || {},
        data: (this.dataObjects[collectionTaskName] || { data: [] }).data.map(product => {
          const barcodes = this.getBarcode(product)

          if (barcodes.find(value => `${value}`.trim() === barcode.raw) && !isIncreased) {
            const scannedCount = product.scannedCount + this.count

            if (scannedCount > product._embedded.orderProduct.count) {
              productWithMaxCount = { ...product, scannedCount }
            } else {
              isIncreased = true
              product.scannedCount = scannedCount
              updatedProduct = { ...product }
            }
          }

          return product
        })
      }

      if (!isIncreased && productWithMaxCount) {
        collection.data = (this.dataObjects[collectionTaskName] || { data: [] }).data.map(product => {
          return product.id == productWithMaxCount.id
            ? productWithMaxCount
            : product
        })
      }

      this.dataObjects = {
        ...this.dataObjects,
        [collectionTaskName] :collection
      }

      this.data = {
        ...this.data,
        objects: this.data.objects.map(x => {
          return x.type === 'collection' && x.entityClass === this.$entities.Orderadmin_Storage_Entity_Assemblage_OrderProductTask
            ? collection
            : x
        })
      }

      const result = {
        product: isIncreased
          ? updatedProduct
          : productWithMaxCount
      }

      return Promise.resolve(result)
    },
    handleOpenTasks () {
      const filter = [
        { field: 'queue', type: 'eq', value: this.queue },
        { field: 'state', type: 'eq', value: 'rejected' }
      ]

      this.$refs.assemblingTasksModal.open(filter, this.$t('Rejected Tasks'))
    },
    handleRequestDataClose () {
      this.$emit('stop-loading')
    },
    handleRequestData (data) {
      this.$refs.requestDataModal.close()

      this.data = {
        ...this.data,
        ...data
      }

      return this.handleSubmit(this.scannedBarcodes)
    },
    forceExecuteScanning () {
      return Promise.resolve(this.scannedBarcodes.length > 0)
        .then(hasBarcodes => {
          return hasBarcodes
            ? this.handleSubmit(this.scannedBarcodes)
            : Promise.resolve([])
        })
    },
    handleOpenRequestData () {
      this.$refs.requestDataModal.open(this.dataObjects['request-data'])
      this.$emit('start-loading')
    },
    handleAddAll () {
      this.count = this.maxAmount
      this.handleItemClick({ data: this.product })
    },
    getBarcode (product) {
      return ((product.instructions || []).find(({ type }) => type === 'barcode-scan') || { data: { barcode: [] } }).data.barcode
    },
    executeInstructions (instructions) {
      this.$emit('start-loading')

      const reducedInstructions = instructions.reduce((acc, instruction) => {
        if (Array.isArray(instruction)) {
          acc = [...acc, ...instruction]
        } else {
          acc.push(instruction)
        }

        return acc
      }, [])

      return this.instructionsService.execute(reducedInstructions)
        .then(result => {
          this.$emit('stop-loading')
          return result
        })
    },
    getCollectionName (entity) {
      return `collection:${entity}`
    },
    handleBarcode (barcode) {
      if (this.data.requiredData && this.data.requiredDataType) {
        const validations = {
          regexp: (value, validation) => {
            const regex = new RegExp(validation)
            return !regex.test(value.raw)
          },
          text: (value, validation) => {
            return value.raw !== validation
          }
        }

        if (!validations[this.data.requiredDataType]) {
          return this.addErrorNotification('Validation is not recognized!')
        }

        if (validations[this.data.requiredDataType](barcode, this.data.requiredData)) {
          return this.addErrorNotification('Barcode does not match scheme!')
        }
      }

      return this.handleSubmit(barcode)
    },
    handleSubmit (entity) {
      const data = this.reduceData(
        [
          'objects',
          'queue',
          'requiredData',
          'requiredDataType',
          'entity',
          'timestamp'
        ],
        {
          timestamp: new Date().toISOString(),
          entity,
          place: this.place
        }
      )

      if (entity && typeof entity === 'object' && entity.raw) {
        data.entity = entity.raw
      }

      const task = this.dataObjects[this.$entities.Orderadmin_Storage_Entity_Assemblage_Task]

      if (task) {
        data.task = task.data.id
      }

      const supplyPlace = this.dataObjects[this.$entities.Orderadmin_DeliveryServices_Entity_DeliveryRequest_Place]

      if (supplyPlace) {
        data.supplyPlace = supplyPlace.data.id
      }

      this.isLoading = true
      this.$emit('start-loading')

      return this.$refs.entityUpdateModal.submit()
        .then(() => {
          return this.$service.assemblingQueue.process(this.queue, data, undefined, 'application/json+event')
        })
        .then(data => {
          this.data = data
          this.scannedBarcodes = []
          this.dataObjects = data.objects.reduce((acc, obj) => {
            const key = obj.type === 'collection'
              ? `collection:${obj.entityClass}`
              : obj.type

            acc[key] = obj

            return acc
          }, {})
        })
        .catch(() => {
          if (this.data.event === 'storage.assembling.scan.item') {
            this.revertChanges(this.product)
            if (!this.fastMode) {
              this.scannedBarcodes = []
            }
          }
        })
        .finally(() => {
          this.$emit('stop-loading')
          this.count = 1
          this.isLoading = false
        })
    },
    handleItemClick (item) {
      this.amountModal = false
      const barcodes = this.getBarcode(item.data)

      if (!barcodes || barcodes.length <= 0) {
        this.addErrorNotification(`No barcodes in that product! Barcode value ${JSON.stringify(barcodes)}`)
        return
      }

      const barcode = {
        value: barcodes[0],
        raw: barcodes[0]
      }

      return this.handleProductScan(barcode)
    },
    handleQueueClick (item) {
      this.queue = item.data.id
      this.adapter = item.data.adapter
      this.data.entityClass = item.type
      this.data.event = item.event

      return this.handleBarcode({ value: item.data.id, raw: item.data.id, type: '' })
        .then(() => {
          this.$emit('show-barcode-field')
        })
    },
    handleClick (item) {
      const actionsByType = {
        'storage.queue.choose': () => {
          this.queue = null
          this.product = null
          this.dataObjects = {}
          this.data = {
            place: this.place,
            objects: [
              {
                type: 'message',
                data: {
                  position: 'bottom',
                  text: 'Choose queue'
                }
              },
              ...this.queues
            ]
          }
        },
        'storage.queue.task.print': () => {
          if (this.dataObjects[this.$entities.Orderadmin_Storage_Entity_Assemblage_Task]) {
            return this.executeInstructions(this.dataObjects[this.$entities.Orderadmin_Storage_Entity_Assemblage_Task].data?.instructions || [])
          }
        },
        'Orderadmin\\Storage\\Entity\\Assemblage\\Queue': this.handleQueueClick,
        'collection': this.handleItemClick,
        'storage.queue.task.reject': () => {
          this.data.event = 'storage.queue.task.reject'
          this.data.entityClass = this.$entities.Orderadmin_Storage_Entity_Assemblage_Task

          return this.handleBarcode({ value: '', raw: '', type: '' })
        },
        'storage.assembling.complete.box': () => {
          this.data.event = 'storage.assembling.complete.box'
          return this.handleBarcode({ value: '', raw: '', type: '' })
        },
        'storage.assembling.finish': () => {
          return this.executeInstructions(this.dataObjects[this.$entities.Orderadmin_Storage_Entity_Assemblage_Task].data?.instructions || [])
            .then(isOk => {
              if (isOk) {
                this.data.event = 'storage.assembling.finish'
      
                // const collectionTaskName = this.getCollectionName(this.$entities.Orderadmin_Storage_Entity_Assemblage_OrderProductTask)
      
                // const scannings = (this.dataObjects[collectionTaskName] || { data: [] }).data.reduce((acc, item) => {
                //   if (item.scannedCount < item._embedded.orderProduct.count) {
                //     acc.push({
                //       id: item.id,
                //       quantity: item._embedded.orderProduct.count - item.scannedCount,
                //       timestamp: new Date().toISOString()
                //     })
                //   }

                //   return acc
                // }, [])
      
                return this.handleBarcode(this.scannedBarcodes)
              }
            })
        },
        'storage.assembling.scan.item': () => {
          return this.executeInstructions(this.dataObjects[this.$entities.Orderadmin_Storage_Entity_Assemblage_Task].data?.instructions || [])
            .then(isOk => {
              if (isOk) {
                this.data.event = 'storage.assembling.scan.item'
      
                const collectionTaskName = this.getCollectionName(this.$entities.Orderadmin_Storage_Entity_Assemblage_OrderProductTask)
      
                const scannings = (this.dataObjects[collectionTaskName] || { data: [] }).data.reduce((acc, item) => {
                  if (item.scannedCount < item._embedded.orderProduct.count) {
                    acc.push({
                      id: item.id,
                      quantity: item._embedded.orderProduct.count - item.scannedCount,
                      timestamp: new Date().toISOString()
                    })
                  }

                  return acc
                }, [])
      
                return this.handleBarcode([...this.scannedBarcodes, ...scannings])
              }
            })
        },
        'storage.assembling.offer.opened': () => {
          // this.data.event = 'storage.assembling.offer.opened'

          const value = this.getPrevScan().map(x => {
            if (x.barcode) {
              return { ...x, offer: item.data.id, shop: this.getShop(item.data) }
            }

            return x
          })

          return this.handleBarcode(value)
        }
      }

      if (actionsByType[item.type]) {
        return actionsByType[item.type](item)
      }

      if (actionsByType[item.event]) {
        return actionsByType[item.event](item)
      }

      if (!item.event) {
        return
      }

      this.data.entityClass = item.type
      this.data.event = item.event

      this.handleBarcode({ value: item.data.id, raw: item.data.id, type: '' })
    },
    getShop (offer) {
      if (!offer._embedded || !offer._embedded.shop) {
        return null
      }

      return offer._embedded.shop.id
    },
    getPrevScan () {
      if (this.dataObjects[this.$entities.Barcode] && this.dataObjects[this.$entities.Barcode].data) {
        if (Array.isArray(this.dataObjects[this.$entities.Barcode].data)) {
          return this.dataObjects[this.$entities.Barcode].data
        }

        if (this.dataObjects[this.$entities.Barcode].prevScannedValue) {
          return this.dataObjects[this.$entities.Barcode].prevScannedValue
        }
      }

      return this.data.prevScannedValue || this.data.prevEntity || []
    },
    reduceData (ignoredKeys = [], defaultValue = {}) {
      return Object.keys(this.data)
        .filter(key => !ignoredKeys.includes(key))
        .reduce((acc, key) => {
          return {
            ...acc,
            [key]: this.data[key] && typeof this.data[key] === 'object'
              ? this.data[key].id
              : this.data[key]
          }
        }, { ...defaultValue })
    },
    handleAmount (e) {
      const value = Number(e)

      if (value < 1) {
        this.count = 1
      } else {
        this.count = value
      }
    },
    handleIncrease () {
      this.count += 1
    },
    handleDecrease () {
      if (this.count <= 1) {
        return
      }

      this.count -= 1
    },
    onFocus () {
      this.$emit('block')
    },
    onFocusOut () {
      this.$emit('unblock')
    }
  }
}
</script>
