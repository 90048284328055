<template>
  <q-btn
    :label="data.label"
    :color="options.color"
    :icon-right="options.icon"
    no-caps
    dark
    @click="handleClick"
  />
</template>

<script>
export default {
  name: 'Option',
  emits: ['click'],
  props: {
    data: {
      type: Object,
      default () {
        return null
      }
    },
    rawData: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  computed: {
    options () {
      if (this.data.options && !Array.isArray(this.data.options) && typeof this.data.options === 'object') {
        return this.data.options
      }

      if (this.rawData.options && !Array.isArray(this.rawData.options) && typeof this.rawData.options === 'object') {
        return this.rawData.options
      }

      return {
        color: 'teal',
        icon: 'reply'
      }
    }
  },
  methods: {
    handleClick () {
      this.$emit('click', { event: this.data.event })
    }
  }
}
</script>
